import storage from "../utils/local_storage"
import apiClient from "./apiClient";

const STRAVA_CLIENT_ID = '56521'; // Replace with your Strava Client ID
const STRAVA_SCOPE = 'read,activity:read_all,activity:read,activity:write,profile:read_all'; // Set the appropriate scope

export const redirectToStravaAuth = (callbackPage=false, force=true) => {
    // console.log({force})
    const redirectUri = callbackPage ? `${process.env.REACT_APP_STRAVA_REDIRECT_URI}?callbackPage=${callbackPage}` : process.env.REACT_APP_STRAVA_REDIRECT_URI;
    const approval_prompt = force ? 'approval_prompt=force' : 'approval_prompt=auto'
    const stravaAuthUrl = `https://www.strava.com/oauth/authorize?client_id=${STRAVA_CLIENT_ID}&response_type=code&redirect_uri=${redirectUri}&${approval_prompt}&scope=${STRAVA_SCOPE}`;
    window.location.href = stravaAuthUrl;
};

export const handleStravaAuthRedirect = async (code, scope) => {
    try {
        // const userEmail = storage.getItem("userEmail")
        const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
        const response = await apiClient.post(`${API_BASE_URL}loginWithStrava`, { code, scope });
        const token = response.data.accessToken
        if (token !== null) storage.setItem('Token', token)
        return "success";
    } catch (error) {
        console.error('Error logging in:', error);
        throw error;
    }
};
