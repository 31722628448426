import './App.css'
import React, {useEffect, useRef, useContext, Suspense, lazy} from 'react';
import {Routes, Route, useLocation } from 'react-router-dom';
import AboutPage from './Pages/AboutPage';
import PrivacyPage from './Pages/PrivacyPage';
import HomePage from './Pages/HomePage';

import TermsPage from './Pages/TermsPage';
// import DashboardPage from './Pages/DashboardPage';
// import ActivityAnalysisPage from './Pages/ActivityAnalysisPage';
import PermissionExplainerPage from './Pages/PermissionExplainerPage';
import storage from "./utils/local_storage"
import { StateContext } from './stateContext';
import StravaRedirectHandler from './components/StravaRedirectHandler';
import SpotifyRedirectHandler from './components/SpotifyRedirectHandler';
import {ReactComponent as ZestyRidesIcon} from '../src/assets/spicy_bike.svg'
import {ReactComponent as PoweredByStravaIcon} from '../src/assets/1.2 strava api logos/powered by Strava/pwrdBy_strava_gray/api_logo_pwrdBy_strava_stack_gray.svg'
import { Helmet } from 'react-helmet';

const DashboardPage = lazy(() => import('./Pages/DashboardPage'));
const ActivityAnalysisPage = lazy(() => import('./Pages/ActivityAnalysisPage'));
function App() {
  const canvasRef = useRef(null);
  const {settings, setSettings, setToastData, toastData, initialSettings} = useContext(StateContext);
  // const navigate = useNavigate();
  const location = useLocation();  // Get the current location

  // function handleLogout(){
  //   storage.removeItem("Token")
  //   // navigate('/')
  // }
  // initial load stuff
  useEffect(()  =>  {
    // make sure settings have all of the keys necessary in case I add more
    // console.log(settings)
    const sortedInitialKeys = Object.keys(initialSettings).sort();
    const sortedSettingsKeys = Object.keys(settings).sort();
    // console.log(settings)
    if (JSON.stringify(sortedInitialKeys) !== JSON.stringify(sortedSettingsKeys)) {
      // console.log("settings not equal, adding new keys")
      const updatedSettings = { ...initialSettings, ...settings };
      setSettings(updatedSettings);
    }

    //open getting started modal if first visit
    if (settings && !settings.got_started){
      handleGettingStarted()
    }
    
  }, []);

  const handleGettingStarted = () => {
    document.getElementById('getting_started_modal').showModal();
    setSettings({...settings, got_started:true})
  }
    
// local storage settings saver
  useEffect(() => {
    storage.setItem("settings", settings);
  }, [settings]);

  // timer to close toast after X seconds
  useEffect(() => {
    let timer;

    if (toastData.show) {
        // Set a timer to automatically close the toast after 30 seconds
        timer = setTimeout(() => {
                  setToastData({...toastData,show: false});
        }, 5000); // 5 seconds
    }

    // Clean-up function to clear the timer
    return () => {
        if (timer) {
            clearTimeout(timer);
        }
    };
  }, [toastData.show]);

  const getTheme = () =>{
    // if (settings.theme === "system") return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    // else 
    return settings.theme
  }

  const setTheme = (e) => {
    // console.log(e.target.checked)
    let these_settings = {...settings}
    if (e.target.checked === true){
      these_settings.theme = "dark"
    } else these_settings.theme = "light"
    setSettings(these_settings)
  }

  return (
  <div data-theme={getTheme()} className=" flex flex-col min-h-screen">
    <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
    {toastData.show && 
    <div className="toast toast-center">
      <div className={`alert alert-${toastData.type} flex`}>
        <p className="text-center">{toastData.text}</p>
        {/* <button onClick={() => setToastData({...toastData, show:false})}>
          Close
        </button> */}
      </div>
    </div>}
    <div className="navbar border-b border-slate-500/10 shadow-sm z-50">
      <div className="navbar-start">
        <div className="dropdown">
          <label tabIndex={0} className="btn btn-ghost btn-circle">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7" /></svg>
          </label>
          <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52">
            <li><a href="/">Home</a></li>
            {storage.getItem("Token") !== null && <li><a href="/dashboard">Dashboard</a></li>}
            {/* <li><a href="/pricing">Pricing</a></li> */}
            <li><a href="/about">About</a></li>
            <li><a href="/privacy-policy">Privacy Policy</a></li>
            <li><a href="/terms">Terms of Service</a></li>
            {storage.getItem("Token") !== null && <li><a href="/" onClick={() => {storage.removeItem("Token")}}>Logout</a></li>}
          </ul>
        </div>
      </div>
      <a className="navbar-center" href="/">
        <ZestyRidesIcon width="35" height="35" className="text-inherit mr-4"/>
        <p className="normal-case text-xl font-semibold">ZestyRides</p>
        {/* <a href="/" className="normal-case text-xl font-semibold" >ZestyRides</a> */}
      </a>
      <div className="navbar-end m-2">
        <div className="tooltip tooltip-bottom" data-tip="Theme">
          <label className="swap swap-rotate">
            
            {/* this hidden checkbox controls the state */}
            <input type="checkbox" className="theme-controller" checked={settings.theme !=="light"} onChange={setTheme} />
            
            {/* sun icon */}
            <svg className="swap-on fill-current w-7 h-7" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5.64,17l-.71.71a1,1,0,0,0,0,1.41,1,1,0,0,0,1.41,0l.71-.71A1,1,0,0,0,5.64,17ZM5,12a1,1,0,0,0-1-1H3a1,1,0,0,0,0,2H4A1,1,0,0,0,5,12Zm7-7a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4A1,1,0,0,0,12,5ZM5.64,7.05a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41l-.71-.71A1,1,0,0,0,4.93,6.34Zm12,.29a1,1,0,0,0,.7-.29l.71-.71a1,1,0,1,0-1.41-1.41L17,5.64a1,1,0,0,0,0,1.41A1,1,0,0,0,17.66,7.34ZM21,11H20a1,1,0,0,0,0,2h1a1,1,0,0,0,0-2Zm-9,8a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V20A1,1,0,0,0,12,19ZM18.36,17A1,1,0,0,0,17,18.36l.71.71a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41ZM12,6.5A5.5,5.5,0,1,0,17.5,12,5.51,5.51,0,0,0,12,6.5Zm0,9A3.5,3.5,0,1,1,15.5,12,3.5,3.5,0,0,1,12,15.5Z"/></svg>
            
            {/* moon icon */}
            <svg className="swap-off fill-current w-7 h-7" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.64,13a1,1,0,0,0-1.05-.14,8.05,8.05,0,0,1-3.37.73A8.15,8.15,0,0,1,9.08,5.49a8.59,8.59,0,0,1,.25-2A1,1,0,0,0,8,2.36,10.14,10.14,0,1,0,22,14.05,1,1,0,0,0,21.64,13Zm-9.5,6.69A8.14,8.14,0,0,1,7.08,5.22v.27A10.15,10.15,0,0,0,17.22,15.63a9.79,9.79,0,0,0,2.1-.22A8.11,8.11,0,0,1,12.14,19.73Z"/></svg>
            
          </label>
        </div>
        
      </div>
    </div>
    <div className="flex flex-grow w-full">
      <Suspense fallback={
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex flex-col justify-center items-center">
          <div className="bg-neutral p-4 rounded-lg flex flex-col items-center justify-center space-y-2">
            <span className="loading loading-spinner loading-lg text-white"></span>
            <p className="text-white text-lg">Loading</p>
          </div>
        </div>}>
        <Helmet>
          <link rel="canonical" href={`https://zestyrides.com${location.pathname}`} />
        </Helmet>
        <Routes>
          <Route path="/" element={<HomePage/>} />
          <Route path="/dashboard" element={<DashboardPage/>} />
          <Route path="/activity/:activityID" element={<ActivityAnalysisPage/>} />
          <Route path="/about" element={<AboutPage/>} />
          <Route path="/terms" element={<TermsPage/>} />
          <Route path="/privacy-policy" element={<PrivacyPage/>} />
          <Route path="/strava-redirect/" element={<StravaRedirectHandler/>} />          
          <Route path="/spotify-redirect" element={<SpotifyRedirectHandler/>} />          
          <Route path="/permission-explainer" element={<PermissionExplainerPage/>} />          
        </Routes>
      </Suspense>
    </div>
    <footer className="footer mt-auto p-4">
      <aside className="flex flex-row w-full items-center justify-between">
        <div className="flex flex-row items-center flex-1">
          <ZestyRidesIcon width="35" height="35" className="text-inherit"/>
          <p className="ml-2">Copyright ZestyRides © 2024 - All right reserved</p>
        </div>
        <PoweredByStravaIcon width="125" className=""/>
      </aside> 
    </footer>
  </div>
  );
}

export default App;
