import React from 'react';
import { redirectToStravaAuth } from '../utils/stravaAuth'; 
import StravaButton from '../assets/btn_strava_connectwith_orange.svg'; // Adjust the path according to your folder structure


function SignupButton({ callbackPage=null, force=false }) {
  return (
      <button onClick={() => redirectToStravaAuth(callbackPage, force)}className="">
          <img src={StravaButton} alt="Login with Strava" className=""/>
      </button>
  );
}

export default SignupButton;