import apiClient from "./apiClient";

const SPOTIFY_CLIENT_ID = 'deb9bc40d5df4e83b7c0a897491b3936'; // Replace with your Spotify Client ID
const SPOTIFY_SCOPE = 'user-read-recently-played,user-top-read'; // Set the appropriate scope for your application

export const redirectToSpotifyAuth = () => {
    const spotifyAuthUrl = `https://accounts.spotify.com/authorize?client_id=${SPOTIFY_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_SPOTIFY_REDIRECT_URI}&scope=${SPOTIFY_SCOPE}&show_dialog=true`;
    // console.log({spotifyAuthUrl})
    // window.location.href = spotifyAuthUrl;
    window.location.assign(spotifyAuthUrl);

};

export const handleSpotifyAuthRedirect = async (code) => {
    try {
        const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
        const response = await apiClient.post(`${API_BASE_URL}loginWithSpotify`, { code });
        // const token = response.data.accessToken;
        // if (token !== null) storage.setItem('spotifyToken', token);
        return "success";
    } catch (error) {
        console.error('Error logging in with Spotify:', error);
        throw error;
    }
};
