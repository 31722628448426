import React from 'react';
// import { StateContext } from '../stateContext';


function AboutPage() {
  return (
  <div className="flex flex-col justify-center mx-auto md:w-3/4 lg:w-1/2 space-y-8 p-4">
    <p className="text-xl text-center">About</p>
    <p className="text-left">I wrote this service after seeing too many boring activities on my Strava feed. There are services to add punny titles and weather but I saw the potential to offer more fun and useable data options such as goals that could help athletes in their athletic pursuits.</p>
    <p className="text-left">I hope you enjoy this service! Please reach out with any questions, comments, or suggestions through the email provided in the user dashboard!</p>
  </div>
  );
}

export default AboutPage;