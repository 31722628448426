import axios from 'axios';
import storage from "./local_storage"


// Create an Axios instance
const apiClient = axios.create({
});

apiClient.interceptors.request.use((config) => {
    const token = storage.getItem('Token');
    if (token !== null) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    // Do something with request error
    return Promise.reject(error);
});

// Response interceptor for handling 401 Unauthorized responses
apiClient.interceptors.response.use(response => {
    // Successful response, just pass it through
    return response;
}, error => {
    // Check for 401 Unauthorized response
    if (error.response && error.response.status === 401) {
        // Redirect to the login page
        storage.removeItem("Token")
        redirectToLogin();
    }
    // Return any other error
    return Promise.reject(error);
});

function redirectToLogin() {
    // Redirect to the login page
    window.location.href = '/';
}

export default apiClient;
