import React from 'react';
// import { StateContext } from '../stateContext';
import SignupButton from '../components/SignupButton'


function PermissionExplainerPage() {
  return (
  <div className="flex flex-col justify-center mx-auto md:w-3/4 lg:w-1/2 md:p-4 p-8 md:text-left text-justify">
    <p className="text-xl font-bold text-center">Permission Explanation</p>
    <p className="">It appears you did not provide all requested permissions to ZestyRides. Props for have a sense of security on the web, but let me explain why we need access to each of the permissions we request.</p>
    <p className=" font-bold pt-4">View data about your public profile (required by Strava)</p>
    <p className="">This is required but we'll explain what this includes anyway. This provides access to very basic profile information similar to what you see when browsing to an athlete's profile that you do not follow and has their profile set to private.</p>
    <p className=" font-bold pt-4">View your complete Strava profile (required by ZestyRides)</p>
    <p className="">This provides access to view your detailed provile, similar to what you see when navigating to your own profile dashboard. We use this to access your gear, measurement preferences, previous public activities, and stats.</p>
    <p className=" font-bold pt-4">View data about your activities (required by ZestyRides)</p>
    <p className="">This permission gives us access to your public activities. If you grant access to private activities, this will be granted by Strava implicitly.</p>
    <p className=" font-bold pt-4">View data about your private activities (optional)</p>
    <p className="">This one is optional for us as well but allows us to Zest your private activities as well. Most people want this but in case you don't want ZestyRides to see these activities, you can uncheck this and we will only zesty your public activities.</p>
    <p className=" font-bold pt-4">Upload your activities from ZestyRides to Strava (required by ZestyRides)</p>
    <p className="">This is the main purpose of ZestyRides. Without this, we can't add to your activity uploads and save them to Strava on your behalf.</p>
    <p className=" pt-4">Hopefully by now we've convinced you and you would like to connect to Strava again with all necessary permissions this time.</p>
    <div className="text-center">
      <SignupButton/>
    </div>
  </div>
  );
}

export default PermissionExplainerPage;