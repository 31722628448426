import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { handleSpotifyAuthRedirect } from '../utils/spotifyAuth';

const SpotifyRedirectHandler = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const handleAuthCode = async () => {
            const urlParams = new URLSearchParams(location.search);
            const code = urlParams.get('code');

            if (code) {
                try {
                    await handleSpotifyAuthRedirect(code);
                    // Redirect the user to another route upon successful authentication
                    navigate('/dashboard');
                } catch (error) {
                    console.error('Error handling Spotify redirect:', error);
                    navigate('/dashboard'); // Redirect to home or login page on error
                }
            } else {
                // Handle the case where the code is not present in the URL
                navigate('/dashboard'); // Redirect to home or login page
            }
        };

        handleAuthCode();
    }, [navigate, location]);

    return (
        <div className="flex grow justify-center">
            <progress className="progress w-1/2 m-10"></progress>
        </div>
    );
};

export default SpotifyRedirectHandler;
