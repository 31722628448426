import React from 'react';
// import { StateContext } from '../stateContext';


function AboutPage() {
  return (
  <div className="flex flex-col text-left mx-auto space-y-8 p-4 md:w-3/4">
    <p className="text-xl text-center">Terms of Use</p>
    <p className="text-left">Your privacy is important to us. That's why we collect as little information as possible while still providing a tailored, feature-rich experience.</p>
    <p className="text-left">Last Updated: 1/13/2024</p>
    <p>
Please read these Terms of Service ("Terms") carefully before using the ZestyRides.com website (the "Service") operated by ZestyRides.com ("us", "we", or "our").
</p><p>
Access to the Service:
Your access to and use of the Service is conditioned upon your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who wish to access or use the Service. By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the terms, you do not have permission to access the Service.
</p><p>
Subscriptions:
Parts of the Service may be billed on a subscription basis ("Subscription(s)"). You will be billed in advance on a recurring and periodic basis ("Billing Cycle"). Subscriptions may renew automatically under the same conditions unless canceled. You can cancel your Subscription renewal through your online account management page.
</p><p>
Payment:
A valid payment method is required to process the payment for your Subscription. You shall provide ZestyRides.com with accurate and complete billing information including full name, address, state, zip code, telephone number, and a valid payment method. By submitting such payment information, you automatically authorize ZestyRides.com to charge all Subscription fees incurred through your account.
</p><p>
Fee Changes:
ZestyRides.com, in its sole discretion, may modify Subscription fees. Any Subscription fee change will become effective at the end of the then-current Billing Cycle.
</p><p>
Refunds:
Except when required by law, paid Subscription fees are non-refundable.
</p><p>
Accounts:
When you create an account with us, you guarantee that the information you provide is accurate, complete, and current at all times. You are responsible for maintaining the confidentiality of your account and password and for all activities that occur under your account.
</p><p>
Intellectual Property:
The Service and its original content, features, and functionality are and will remain the exclusive property of ZestyRides.com and its licensors.
</p><p>
Links To Other Web Sites:
Our Service may contain links to third-party web sites or services that are not owned or controlled by ZestyRides.com. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third party web sites or services.
</p><p>
Termination:
We may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, for any reason whatsoever, including, without limitation, a breach of the Terms.
</p><p>
Indemnification:
You agree to defend, indemnify, and hold harmless ZestyRides.com and its licensees and licensors, and their employees, contractors, agents, officers, and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs, and expenses.
</p><p>
Limitation Of Liability:
ZestyRides.com shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your access to or use of, or inability to access or use the Service.
</p><p>
Disclaimer:
Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis without warranties of any kind.
</p><p>
Governing Law:
These Terms shall be governed and construed in accordance with the laws of the jurisdiction in which ZestyRides.com operates, without regard to its conflict of law provisions.
</p><p>
Changes to Terms:
We reserve the right to modify or replace these Terms at any time at our sole discretion. We will provide notice of any changes by posting the new Terms on our website. By continuing to access or use our Service after revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Service.
</p>
<p>
Contact Us:
If you have any questions about these Terms, please contact us through the email provided on the user dashboard page.
</p>

</div>
  );
}

export default AboutPage;