import React from 'react';
// import { StateContext } from '../stateContext';


function AboutPage() {
  return (
  <div className="flex flex-col text-left mx-auto space-y-8 p-4 md:w-3/4">
    <p className="text-xl text-center">Privacy Policy</p>
    <p className="text-left">Your privacy is important to us. That's why we collect as little information as possible while still providing a tailored, feature-rich experience.</p>
    <p className="text-left">We use Strava's API to retrieve the activity and athlete information needed to modify activity details correctly. The only additional piece of information we request, your email, is used to stay in touch with you about updates to the application and your subscription.</p>
    <p className="text-left">We do not sell or share any of your information with third parties.</p>
    <p className="text-left">We do store some activity information from Strava such as activity titles, descriptions, and timestamps to track activity updates and keep from overwriting user's activity descriptions.</p>
    <p className="text-left">Additionally, we store ZestyRides user preferences to deliver a tailored experience. These are not considered personal information in any way.</p>
    <p className="text-left">Our payment processor, Stripe, may save payment details on their servers.</p>
    <p className="text-left">Your continued use of our site will be regarded as acceptance of our practices around privacy and personal information.</p>
  </div>
  );
}

export default AboutPage;