import React , {useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { handleStravaAuthRedirect } from '../utils/stravaAuth';

const StravaRedirectHandler = () => {
    // const { setToastData } = useContext(StateContext);
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        const handleAuthCode = async () => {
            const urlParams = new URLSearchParams(location.search);
            const code = urlParams.get('code');
            const scope = urlParams.get('scope');
            // console.log({scope})
            const callbackPage = urlParams.get('callbackPage'); // Extract your custom callbackPage parameter
            console.log(callbackPage)
            async function delay() {
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
            // console.log(scope)
            const allowedScopes = [
                "read,activity:write,activity:read,profile:read_all",
                "read,activity:write,activity:read,activity:read_all,profile:read_all",
                "read,activity:read_all,activity:read,activity:write,profile:read_all"
            ].map(scopeString => scopeString.split(',').sort().join(',')); // Sort and re-join each scope string

            const receivedScopes = scope?.split(',').sort().join(',');
            if (!allowedScopes.includes(receivedScopes)) {
                console.log("you did not provide all necessary permissions")
                navigate("/permission-explainer")
            } else if (code) {
                try {
                    const result = await handleStravaAuthRedirect(code, scope);
                    console.log({result})
                    delay()
                    // Process the access token as needed
                    // Redirect the user to another route upon successful authentication
                    if (callbackPage) navigate(callbackPage)
                    else navigate('/dashboard');
                } catch (error) {
                    console.error('Error redirecting:', error);
                    // Handle error (e.g., show error message, redirect to error page, etc.)
                    // setToastData({type:"error", text:"Error logging in. Try again.", show:true})
                    navigate('/');
                }
            } else {
                // Handle the case where the code is not present in the URL
                navigate('/'); // Redirect to home or login page
            }
        };

        handleAuthCode();
    }, [navigate, location]);

    return (
        <div className="flex grow justify-center">
          <progress className="progress w-1/2 m-10"></progress>
        </div>
    );
};

export default StravaRedirectHandler;
